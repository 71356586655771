import React from 'react';
import { matchService } from '../_services';
import { useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import MatchDetail from '../components/matchDetail';

export default function MatchPage() {
  const [match, setMatch] = useState(null);

  const { id } = useParams();

  const fetchData = async () => {
    const response = await matchService.getMatchDetail(id)
    setMatch(response[0]);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div >
      {match && (
       <MatchDetail match={match} showAddOns={true}></MatchDetail> )}
    </div>

  );
}