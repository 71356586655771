import React from 'react';
import { matchService } from '../_services';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default function Admin() {
    
    const handleSubmit = async e => {
        e.preventDefault();

        matchService.updateMatches()
            .then(
                response => {
                    swal("Success", response + ' matches updated', "success", {
                        buttons: false,
                        timer: 2000,
                    })
                }
            );
    }


    return (
        <div>
            <Card><CardContent>
                <form noValidate onSubmit={handleSubmit}>
                    <p> Click to update matches, synchronized from NeVoBo feed</p>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Update
                    </Button>
                </form>
            </CardContent></Card>
        </div>

    );
}
