import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import { authenticationService } from '../_services';

const user = JSON.parse(localStorage.getItem('user'));

export default function Profiel() {

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();

    
    authenticationService.updateUser(localStorage.getItem('userId'), password, newPassword)
      .then(
        response => {
          console.log(response);
          if (response.message == 'Successful update.') {
            swal("Success", "Password change success!. You will be logged out.", "success", {
              buttons: false,
              timer: 2000,
            })
              .then((value) => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("user");
                window.location.href = "/";
              });
          } else {
            swal("Fout", response.message, "error");
          }
        }
      ).catch(error => 
        swal("Fout", error, "error")
      );
  }
  return (
    <div className={classes.root}>
      <h2>{user}</h2>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          name="password"
          label="Huidig wachtwoord"
          type="password"
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newPassword"
          name="newPassword"
          label="Nieuw wachtwoord"
          type="password"
          onChange={e => setNewPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Wachtwoord wijzigen
        </Button>
      </form>
    </div>
  );
}