import React from 'react';
import ParticipationDetail from '../components/participation';
import { makeStyles } from "@material-ui/core/styles";

export default function Participation() {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ParticipationDetail show='upComming'></ParticipationDetail> 
      <hr />
      <ParticipationDetail show='past'></ParticipationDetail> 
    </div>
  );
}
