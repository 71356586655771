import React, { useEffect, useState } from 'react';
import { matchService } from '../_services';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from "moment";

import swal from 'sweetalert';
import SaveIcon from '@mui/icons-material/Save';

function MatchDetailParticipation(opts) {
    const [match] = useState(opts.match);
    const [player] = useState(opts.player);
    
    const [present, setPresent] = useState(opts.player.present);
    const [reason, setReason] = useState(opts.player.remark);
    const [submitted, setSubmitted] = useState(false);

    const changeParticipation = (e) => {
        const value = e.target.value;
        setPresent(value);
        setSubmitted(false);
        if (value != 0) {
            setReason(null)
        }
    };

    const changeReason = (e) => {
        const value = e.target.value;
        setReason(value);
    };

    const updateParticipation = async e => {
        e.preventDefault();

        match.userId = player.id;
        match.present = present;
        match.remark = reason;
        matchService.updateParticipation(match)
            .then(
                response => {
                    setSubmitted(true);
                },
                error => {
                    swal("Fout", error, "error");
                }
            );
    }

  

    return (
        <div className="wrapper_container">
            <b>{player.first_name}</b>
            <form key={match.id + '_form_' + player.id} onSubmit={updateParticipation}>
                <div className="wrapper">
                    <input type="radio" name="select" id={match.id + ' ' + player.id + '-option-1'} value={null} key={match.id + ' ' + player.id + '_unknown'}
                        checked={present == 'null' || present === null}
                        onChange={changeParticipation}></input>
                    <input type="radio" name="select" id={match.id + ' ' + player.id + '-option-2'} value="1" key={match.id + ' ' + player.id + '_yes'}
                        checked={present == 1}
                        onChange={changeParticipation}></input>
                    <input type="radio" name="select" id={match.id + ' ' + player.id + '-option-3'} value="0" key={match.id + ' ' + player.id + '_no'}
                        checked={present == 0}
                        onChange={changeParticipation} ></input>

                    <label htmlFor={match.id + ' ' + player.id + '-option-1'} className={present == null ? 'option selected unknown' : 'option'}>
                        <span>Onbekend</span>
                    </label>
                    <label htmlFor={match.id + ' ' + player.id + '-option-2'} className={present == 1 ? 'option selected yes' : 'option'}>
                        <span>Ja</span>
                    </label>
                    <label htmlFor={match.id + ' ' + player.id + '-option-3'} className={present == 0 ? 'option selected no' : 'option'}>
                        <span>Nee</span>
                    </label>

                    <div className="submitbox">
                        {(submitted === true ?
                            (<CheckBoxIcon color="success" fontSize='large' />) : 
                            <Button
                            type="submit"
                            variant="contained"
                            color='primary'
                            disabled={present == 0 && !reason ? true : opts.player.present === present ? true : false}
                        >
                            <SaveIcon />
                        </Button>)}
                        </div>
                </div>
                {(present == 0 ?
                    (<div className="reasonBox"><label>Reden:</label>
                        <input type='text'
                            name="reason" defaultValue={reason}  required={present == 0} onChange={changeReason} />
                    </div>)
                    : null
                )}


                {/* 
                <div className="radio" key={opts.match.id + '_unknownradio'}>
                    <label key={match.id + '_unknownlabel'}>
                        <input
                            type="radio"
                            value="null"
                            name="options"
                            key={match.id + '_unknown'}
                            checked={present == 'null' || present === null}
                            onChange={changeParticipation} />
                        Onbekend
                    </label>
                </div>
                <div className="radio" key={opts.match.id + '_yesradio'}>
                    <label
                        key={match.id + '_yeslabel'}>
                        <input
                            type="radio"
                            value="1"
                            name="options"
                            key={match.id + '_yes'}
                            checked={present == 1}
                            onChange={changeParticipation} />
                        Ja
                    </label>
                </div>
                <div className="radio" key={opts.match.id + '_noradio'}>
                    <label key={match.id + '_nolabel'}>
                        <input
                            type="radio"
                            value="0"
                            name="options"
                            key={match.id + '_no'}
                            checked={present == 0}
                            onChange={changeParticipation} />
                        Nee
                    </label>
                </div> */}

            </form></div>

    )
};
export default MatchDetailParticipation;