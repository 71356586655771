import { authHeader, handleResponse } from '../_helpers';

export const standService = {
    getStand
};

async function getStand() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `stand.php`, requestOptions);
    return handleResponse(response);
}

