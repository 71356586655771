import React, { useEffect, useState } from 'react';
import { matchService } from '../_services';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from "moment";

import swal from 'sweetalert';
import SaveIcon from '@mui/icons-material/Save';

function MatchAddons(opts) {
    const [match] = useState(opts.match);

    const [userWashedId, setUserWashedId] = useState(opts.match.userWashedId);
    const [userBallsId, setUserBallsId] = useState(opts.match.userBallsId);
    const [userBottlesId, setUserBottlesId] = useState(opts.match.userBottlesId);

    const [submitted, setSubmitted] = useState(false);
    const [matchPlayers, setMatchPlayers] = useState(null);

    const fetchData = async () => {
        const response = await matchService.getMatchPlayers(opts.match.id)
        setMatchPlayers(response)
    }

    const changeWashed = (e) => {
        const playerId = e.target.value;
        setUserWashedId(playerId);
    };
    const changeBalls = (e) => {
        const playerId = e.target.value;
        setUserBallsId(playerId);
    };
    const changeBottles = (e) => {
        const playerId = e.target.value;
        setUserBottlesId(playerId);
    };

    useEffect(() => {
        fetchData()
        console.log(match);
    }, [])

    const updateMatchAddons = async e => {
        e.preventDefault();

        match.userWashedId = userWashedId;
        match.userBallsId = userBallsId;
        match.userBottlesId = userBottlesId;
        matchService.updateMatch(match)
            .then(
                response => {
                    setSubmitted(true);
                },
                error => {
                    swal("Fout", error, "error");
                }
            );
    }

    useEffect(() => {
        //setMatch(opts.match);
    }, [])

    return (
        <div className="wrapper_container">
            <form key={match.id + '_form'} onSubmit={updateMatchAddons}>
                    <div className='addOns'>
                  <hr />
                    <table>
                        <tbody>
                        <tr>
                            <td>Wassen</td>
                            <td><select id="washed" value={userWashedId} onChange={changeWashed}>
                                <option value={null}>Selecteer speler</option>
                                {matchPlayers && matchPlayers.map((player, index) => (
                                    <option key={index} value={player.id}>
                                        {player.first_name}
                                    </option>))}
                            </select></td>
                        </tr>
                        <tr>
                            <td>Flessen</td>
                            <td><select id="bottles" value={userBottlesId} onChange={changeBottles}>
                                <option value={null}>Selecteer speler</option>
                                {matchPlayers && matchPlayers.map((player, index) => (
                                    <option key={index} value={player.id}>
                                        {player.first_name}
                                    </option>))}
                            </select></td>
                        </tr>
                        <tr>
                            <td>Ballen</td>
                            <td><select id="balls" value={userBallsId} onChange={changeBalls}>
                                <option value={null}>Selecteer speler</option>
                                {matchPlayers && matchPlayers.map((player, index) => (
                                    <option key={index} value={player.id}>
                                        {player.first_name}
                                    </option>))}
                            </select></td>
                        </tr>
                        <tr><td colSpan={2}>
                               <div className="submitbox">
                        {(submitted === true ?
                            (<CheckBoxIcon color="success" fontSize="large" />) :
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                <SaveIcon />
                            </Button>)}
                    </div></td></tr>
                    </tbody>
                    </table>
                   
                    </div>
            </form></div>

    )
};
export default MatchAddons;