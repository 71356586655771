import React from "react";
import { useEffect, useState } from 'react';
import { matchService } from '../_services';
import MatchParticipation from '../components/matchParticipation';

function ParticipationDetail(opts) {
  const [matches, setMatches] = useState();

  const fetchData = async () => {
    if (opts.show === 'upComming') {
      const response = await matchService.getMyMatchesUpcomming(localStorage.getItem('userId'));
      setMatches(response);
    } else {
      const response = await matchService.getMyMatchesPast(localStorage.getItem('userId'));
      setMatches(response);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div>
      {matches &&
        matches.map((match) => {
          return (
            <MatchParticipation match={match} key={match.id + '_detail'}></MatchParticipation>
          )
        })
      }
    </div>
  );
};
export default ParticipationDetail;