import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEffect, useState } from 'react';
import { matchService } from '../_services';
import { Link } from "react-router-dom";

function ParticipationList(opts) {
    const [matches, setMatches] = useState();
    const [players, setPlayers] = useState(null);
    const [matchStats, setMatchStats] = useState(null);

    const useStyles = makeStyles((theme) => ({
    }));
    const classes = useStyles();

    const fetchData = async () => {
        const matchResponse = await matchService.getMatchOverview();
        setMatches(matchResponse);

        const playerResponse = await matchService.getPlayers()
        setPlayers(playerResponse)

        const matchStatsResponse = await matchService.getMatchStats()
        setMatchStats(matchStatsResponse)
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className={classes.root}>
            <table className='presentTable'>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        {players &&
                            players.map((player) => (
                                <th key={player.id} className="rotated-text">
                                    <div><span>{player.first_name}</span></div>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {matches &&
                        matches.map((match) => (
                            <tr key={match.id}>
                                <td align="right">
                                    <small>
                                        {moment(match.date).locale('nl').format("D-MM")}
                                    </small>
                                </td>
                                {players &&
                                    players.map((player) => (
                                        <td key={'pl_' + player.id}>
                                            {matchStats &&
                                                matchStats.filter(stats => stats.userId == player.id && stats.matchId == match.id).map((stat) => (
                                                    <div className={'present present_' + stat.present} key={'mpl_' + player.id + '_' + match.id}>
                                                        {(() => {
                                                            switch (stat.present) {
                                                                case 1:
                                                                    return '+';
                                                                case 0:
                                                                    return '-';
                                                                case null:
                                                                    return '?';
                                                                default:
                                                                    return null;
                                                            }
                                                        })()}
                                                    </div>

                                                ))}
                                        </td>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </table>
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Speler</th>
                        <th>Aanwezig</th>
                        <th>Onbekend</th>
                        <th>Afwezig</th>
                    </tr>
                </thead>
                <tbody>
                    {players &&
                        players.map((player) => (
                            <tr key={player.id} className="rotated-text">
                                <td>{player.first_name}</td>
                                <td className={'present present_1'}>  {matchStats &&
                                    <span>{matchStats.filter(stats => stats.userId === player.id && stats.present === 1).length}</span>}
                                </td>
                                <td className={'present present_null'}>  {matchStats &&
                                    <span>{matchStats.filter(stats => stats.userId === player.id && stats.present === null).length}</span>}
                                </td>
                                <td className={'present present_0'}>  {matchStats &&
                                    <span>{matchStats.filter(stats => stats.userId === player.id && stats.present === 0).length}</span>}
                                </td>

                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    )
};
export default ParticipationList;