import { React, useState, useEffect } from 'react';
import moment from "moment";

import { matchService } from '../_services';

import TeamPlayers from '../components/teamPlayers';
import MatchAddons from '../components/matchAddons';
import MatchDetailParticipation from '../components/matchDetailParticipation';

function MatchDetail(opts) {
    const [match] = useState(opts.match);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const [matchPlayers, setMatchPlayers] = useState(null);

    const fetchData = async () => {
        const response = await matchService.getMatchPlayers(opts.match.id)
        setMatchPlayers(response)
        console.log(response);
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            {match && match.date != null && (
                <span>
                    <span className='matchTime'>{moment(match.date).locale('nl').format("dddd D MMMM HH:mm")} uur: </span>
                    <span className='matchName'>{match.name}</span>
                    <span className='matchDescription'><small><i>{match.description}</i></small></span>
                    {match.remark &&
                        <span className='matchDescription'><small><b><i>{match.remark}</i></b></small></span>
                    }
                    <span className='matchPresence'>
                        <ul>
                            <li>Aanwezig: <div className="circle present">{match.presentTrue}</div></li>
                            <li>Afwezig: <div className="circle off">{match.presentFalse}</div></li>
                            <li>Onbekend: <div className="circle unknown">{match.presentUnknown}</div></li>
                        </ul>
                    </span>
                    <TeamPlayers match={match}></TeamPlayers>
                    {opts.showAddOns === true && (
                        <span>
                            <MatchAddons match={match}></MatchAddons>
                            {currentUser.isAdmin === 1 && (
                                <span>
                                    {matchPlayers && matchPlayers.map((player, index) => (
                                        <MatchDetailParticipation key={'index_' + index} match={match} player={player}></MatchDetailParticipation>
                                    ))}
                                </span>
                            )}
                        </span>
                    )}
                </span>)}
        </div>
    )
};
export default MatchDetail;