import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './pages/signin';
import Profile from './pages/profile';
import Admin from './pages/admin';
import Statistics from './pages/statistics';
import Participation from './pages/participation';
import Home from './pages/home';
import Matches from './pages/matches';
import Claims from './pages/claims';
import Stand from './pages/stand';
import MatchPage from './pages/matchPage';
import Navbar from './components/navbar';
import './App.css';

// function Layout(props) {
function App(props) {
  
  const token = localStorage.getItem('accessToken');

  if (!token) {
    return <Signin />
  }

  return (
      <div>
      <BrowserRouter>
          <Navbar />
        <Routes>
          <Route path='/profile' element={<Profile />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/statistics' element={<Statistics />} />
          <Route path='/participation' element={<Participation />} />
          <Route path='/home' element={<Home />} />
          <Route path='/matches' element={<Matches />} />
          <Route path='/claims' element={<Claims />} />
          <Route path='/stats' element={<Statistics />} />
          <Route path='/stand' element={<Stand />} />
          <Route path="/match/:id" element={<MatchPage/>} />
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
     </div>
  );
}

export default App;