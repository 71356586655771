import { authHeader, handleResponse } from '../_helpers';

export const matchService = {
    getMatchOverview,
    getMatchUpcomming,
    getMatchesUpcomming,
    getMatchesPast,
    getMyMatchesUpcomming,
    getMyMatchesPast,
    getMyMatches,
    updateMatches,
    updateParticipation,
    getMatchDetail,
    getMatchPlayers,
    getPlayers,
    updateMatch,
    getMatchStats
};

 async function getMatchOverview() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `match_list.php`, requestOptions);
     return handleResponse(response);
}

async function getMatchUpcomming() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `match_upcomming.php`, requestOptions);
     return handleResponse(response);
}

async function getMatchesUpcomming() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `matches_upcomming.php`, requestOptions);
     return handleResponse(response);
}


async function getMatchesPast() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `matches_past.php`, requestOptions);
     return handleResponse(response);
}

async function getMyMatchesUpcomming(userId) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            userId
        }),
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `my_match_list_upcomming.php`, requestOptions);
     return handleResponse(response);
}

async function getMyMatchesPast(userId) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            userId
        }),
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `my_match_list_past.php`, requestOptions);
     return handleResponse(response);
}

async function getMyMatches(userId) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            userId
        }),
        headers: authHeader()
    };
    const response = await fetch(process.env.REACT_APP_API_URL + `my_match_list.php`, requestOptions);
     return handleResponse(response);
}

async function updateParticipation(match) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            match
        }),
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `matchplayer_update_single.php`, requestOptions).then(handleResponse);
}

async function getMatchDetail(matchId) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            matchId
        }),
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `match_detail.php`, requestOptions).then(handleResponse);
}

async function getMatchPlayers(matchId) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            matchId
        }),
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `matchplayer_list.php`, requestOptions).then(handleResponse);
}

async function getPlayers() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `player_list.php`, requestOptions).then(handleResponse);
}

async function updateMatches() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `matches_update.php`, requestOptions).then(handleResponse);
}

async function updateMatch(match) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            match
        }),
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `match_update.php`, requestOptions).then(handleResponse);
}


async function getMatchStats() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `match_stats.php`, requestOptions).then(handleResponse);
}
