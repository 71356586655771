import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { useState } from 'react';

let pageList = [
    { "title": "Home", "page": "/" },
    { "title": "Wedstrijden", "page": "/matches" },
    { "title": "Deelname", "page": "/participation" },
    { "title": "Afspraken", "page": "/claims" },
    { "title": "Statistieken", "page": "/stats" },
    { "title": "Stand", "page": "/stand" },
    { "title": "Admin", "page": "/admin" }
];

let settingList = [
    { "title": "Profiel", "page": "profile"}
]


function Navbar() {
    const [selectedItem, setSelectedItem] = useState('Home');
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        large: {
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
    }));
    const classes = useStyles();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (e) => {
        setAnchorElNav(null);
        const value = e.target.innerText;
        if(value) {
            setMenuItem(value);
        }
    };

    const handleCloseUserMenu = (e) => {
        setAnchorElUser(null);
        const value = e.target.innerText;
        if(value) {
            setMenuItem(value);
        }
    };

    const setMenuItem = (page) => {
        setSelectedItem(page);
    }

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        window.location.href = "/";
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            TeamInfo
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pageList.map((page) => (

                                    <MenuItem key={page.title} component={Link}
                                        to={page.page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            TeamInfo
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pageList.map((page) => (
                                  <MenuItem key={page.title} title={page.title} component={Link} selected={selectedItem.indexOf(page.title) >= 0}
                                  to={page.page} defaultValue={page.page} onClick={handleCloseNavMenu}>
                                  <Typography textAlign="center">{page.title}</Typography>
                              </MenuItem>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settingList.map((setting) => (

                                    <MenuItem component={Link}
                                        key={setting.title} value={setting.title} 
                                        to={setting.page} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting.title}</Typography>
                                    </MenuItem>
                                ))}
                                <MenuItem onClick={handleLogout}><Typography textAlign="center">Logout</Typography></MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar></div>
    );
};
export default Navbar;
