import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
export default function Claims() {

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
    <h2>Afspraken seizoen 2024/2025</h2>
    <p>
    <b>Bij deze afspraken die we afgelopen woensdag hebben gemaakt:</b>
    ⁠<ul>⁠
    <li>spelverdelers: Wilco, Lasse, Emil, Erik</li>
    <li>⁠mid: Martijn, Koen, Willem</li>
    <li>⁠⁠Buiten/diagonaal: Arjen, Matthijs, AJ, Tim</li>
    <li>Net als vorig jaar gaan we weer voor de middenmoot.</li>
    <li>In de basis wisselen we sociaal, en speelt iedereen die mee gaat. Maar als we voor de punten even niet sociaal moeten wisselen kan dit een keer voorkomen.</li>
    <li>⁠⁠We proberen zoveel mogelijk te trainen.</li>
    <li>Afmelden voor een training bij Erik uiterlijk zondagavond. Dus NIET in de groepsapp afmelden.</li>
    <li>⁠⁠Martijn richt de app in, aan eenieder de vraag deze even in te vullen met je aanwezigheid.</li>
    <li>⁠⁠Shirts wassen op nummer.</li>
    <li>⁠⁠Minimaal 45min voor start van de wedstrijd in de zaal aanwezig zijn. Dit betekent bij sommige thuiswedstrijden al om 18.45u, dus dat kan krap worden.</li>
    <li>⁠⁠Erik, Wilco en/of Matthijs bepalen in principe de opstelling.</li>
    <li>⁠⁠time-outs regelen de bankzitters. Zodat degenen in het veld zich volledig kunnen richten op het spelen.</li>
    <li>⁠⁠Fluiten: Erik, Wilco, Willem en indien nodig Emil.</li>
    <li>⁠⁠De anderen draaien een bardienst of sluiten een keer.</li>
    <li>⁠⁠Koen maakt de indeling en gaat niet bedelen wie wanneer kan. Graag zelf even wisselen als je niet kunt.</li>
    <li>⁠⁠Het bonnetje wordt met een tikkie verdeeld over diegene die aanwezig waren.</li>
    <li>⁠⁠Emil beheert de ballen en Martijn de flesjes.</li>
    </ul>
    </p>
    </div>
  );
}