import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEffect, useState } from 'react';
import { matchService } from '../_services';
import { Link } from "react-router-dom";

function WashList(opts) {
    const [matches, setMatches] = useState();
    const [players, setPlayers] = useState(null);

    const useStyles = makeStyles((theme) => ({
    }));
    const classes = useStyles();

    const fetchData = async () => {
        const response = await matchService.getMatchOverview();
        setMatches(response);

        const playerResponse = await matchService.getPlayers()
        setPlayers(playerResponse)
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className={classes.root}>
            <table>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Aantal</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {players &&
                        players.map((player) => (
                            <tr key={player.id}>
                                <td>{player.first_name}</td>
                                <td>{matches &&
                                    <span>{matches.filter(match => match.userWashedId === player.id).length}</span>
                                }
                                </td>
                                <td>
                                    {matches &&
                                        matches.filter(match => match.userWashedId === player.id).map((match) => (
                                            <span key={player.id + '_' + match.id}><small>{moment(match.date).locale('nl').format("D-MM")}</small>&nbsp;</span>
                                        ))}
                                </td>

                            </tr>
                        ))}
                </tbody></table>
        </div>
    )
};
export default WashList;