import React from 'react';
import StandDetails from '../components/standDetails';
import { makeStyles } from "@material-ui/core/styles";
export default function Stand() {

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StandDetails></StandDetails>
    </div>
  );
}