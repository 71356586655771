import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { standService } from '../_services';
import { matchService } from '../_services';
import { useEffect, useState } from 'react';

function SeasonData() {
    const [standDetails, setStand] = useState();
    const [upcommingMatches, setUpCommingMatches] = useState(0);
    const [playedMatches, setPlayedMatches] = useState(0);
    const [standNr, setStandNr] = useState(0);

    const useStyles = makeStyles((theme) => ({
    }));
    const classes = useStyles();

    const fetchData = async () => {
            const standResponse = await standService.getStand();
            setStand(standResponse);
            standResponse.map((standDetail, i) => {     
                if(standDetail.team.indexOf('Time Out') >= 0) {
                    setStandNr(standDetail.nummer);
                }             
             })

            const upCommingMatches = await matchService.getMatchesUpcomming();
            setUpCommingMatches(upCommingMatches.length);

            const playedMatches = await matchService.getMatchesPast();
            setPlayedMatches(playedMatches.length);

    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className={classes.root}>
            {playedMatches > 0 &&
                <div>
                    <span className='matchDescription'>
        <span><b>{playedMatches}</b></span> van <span><b>{upcommingMatches + playedMatches}</b></span> wedstrijden gespeeld, H3 staat <span><b>{standNr}e</b></span>
        </span>
               
                </div>
            } 
             {playedMatches <= 0 &&
             <span>&nbsp;</span>
}
        </div>
    )
};
export default SeasonData;