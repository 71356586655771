import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { standService } from '../_services';
import { useEffect, useState } from 'react';

function StandDetails() {
    const [standDetails, setStand] = useState();

    const useStyles = makeStyles((theme) => ({
    }));
    const classes = useStyles();

    const fetchData = async () => {
            const response = await standService.getStand();
            setStand(response);
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className={classes.root}>
            {standDetails &&
                <div><table className='table' border='0' cellSpacing='0' cellPadding='0'>
                    <thead>
                        <tr key='head'><th>Nr</th>
                        <th align="left">Team</th>
                        <th>W</th>
                        <th>P</th>
                        <th>S+</th>
                        <th>S-</th>
                        <th>P+</th>
                        <th>P-</th>
                        </tr>
                        </thead>
                <tbody>
                
                    {standDetails.map((standitem, index) => (
                         <tr className={standitem.team.indexOf('Time Out') >= 0 ? 'standingContainer bold' : 'standingContainer'} key={index + 'standDetail'}>
                         <td key={index + 'nr'}>{standitem.nummer}</td>
                            <td key={index + 'team'}>{standitem.team.replace('\\\'', ' \'')}</td>
                            <td key={index + 'w'}>{standitem.wedstrijden}</td>
                            <td key={index + 'p'}>{standitem.punten}</td>
                            <td key={index + 'sv'}>{standitem.setsvoor}</td>
                            <td key={index + 'st'}>{standitem.setstegen}</td>
                            <td key={index + 'pv'}>{standitem.puntenvoor}</td>
                            <td key={index + 'pt'}>{standitem.puntentegen}</td>
                       </tr>
                    ))}
                    </tbody>
                </table>
                </div>
            }
        </div>
    )
};
export default StandDetails;