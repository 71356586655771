import React from "react";
import { matchService } from '../_services';
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


function TeamPlayers(opts) {
    const [matchPlayers, setMatchPlayers] = useState(null);

    const fetchData = async () => {
        const response = await matchService.getMatchPlayers(opts.match.id)
        setMatchPlayers(response)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const useStyles = makeStyles((theme) => ({
    }));
    const classes = useStyles();

    return (
        <div className='matchPlayerBlock'>
            <ul className='matchPlayerList'>
                {matchPlayers && matchPlayers.map((player, index) => (<li key={index}>
                    {(player.present === null ?
                        (<HelpIcon className='presence unknown'></HelpIcon>)
                        : null
                    )}
                    {(player.present === 1 ?
                        (<CheckCircleIcon className='presence present'></CheckCircleIcon>)
                        : null
                    )}{(player.present === 0 ?
                        (<RemoveCircleIcon className='presence off'></RemoveCircleIcon>)
                        : null
                    )} {player.first_name} {player.last_name} {(player.remark && player.remark.length > 0 ?
                        (<i> * {player.remark}</i>)
                        : null
                    )}
                </li>))}
            </ul>
        </div>
    )
};
export default TeamPlayers;