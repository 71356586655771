import { authenticationService } from '../_services';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            console.log(response);
            if ([401, 403, 500].indexOf(response.status) !== -1 
            && (response.url.indexOf('login.php') < 0)
            && (response.url.indexOf('user_update.php') < 0)
            ) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}