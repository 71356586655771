import React from 'react';
import { matchService } from '../_services';
import { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import 'moment/locale/nl';
import MatchDetail from '../components/matchDetail';
import SeasonData from '../components/seasonData';

export default function Home() {

  const user = JSON.parse(localStorage.getItem('user'));
  const [matches, setMatches] = useState();

  const fetchData = async () => {
    const response = await matchService.getMatchUpcomming()
    setMatches(response)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card><CardContent>
        <h2 align='center'>Welkom {user}!</h2>
        <SeasonData></SeasonData>
        <hr />
        {matches && <div>
          <MatchDetail match={matches[0]} showAddOns={false}></MatchDetail>
        </div>}
      </CardContent> </Card> </div>
  );
}