import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEffect, useState } from 'react';
import { matchService } from '../_services';
import { Link } from "react-router-dom"; 

function MatchList(opts) {
    const [matches, setMatches] = useState();

    const useStyles = makeStyles((theme) => ({
    }));
    const classes = useStyles();

    const fetchData = async () => {
        if (opts.show === 'upComming') {
            const response = await matchService.getMatchesUpcomming();
            setMatches(response);
        } else {
            const response = await matchService.getMatchesPast();
            setMatches(response);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className={classes.root}>
            {matches &&
                <div>
                    {matches.map((match) => (
                        <span className='matchListContainer' key={match.id}>
                            <Link to={'/match/' + match.id}>
                                <span className='matchListTime'>{moment(match.date).locale('nl').format("dddd D MMMM HH:mm")} uur: </span>
                                <span className='matchListName'>{match.name}</span>
                                {match.remark &&
                                <span className='matchListRemark'>
                                    {match.remark}</span>
                                }
                                <span className='matchPresence'>
                                    <ul>
                                        <li>Aanwezig: <div className="circle present">{match.presentTrue}</div></li>
                                        <li>Afwezig: <div className="circle off">{match.presentFalse}</div></li>
                                        <li>Onbekend: <div className="circle unknown">{match.presentUnknown}</div></li>
                                    </ul>
                                </span> 
                              
                                </Link>
                        </span>
                    ))}
                </div>
            }
        </div>
    )
};
export default MatchList;