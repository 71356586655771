import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ParticipationList from "../components/participationList"
import WashList from "../components/washList"

export default function Statistics() {

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card><CardContent>
        <h2>Deelname:</h2>
        <ParticipationList></ParticipationList>
        <span className='break'></span>
        <h2>Wassen:</h2>
        <WashList></WashList>
      </CardContent> </Card> </div>

  );
}